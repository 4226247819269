

/* import the necessary Bootstrap files */
$primary: #3F4662;
$warning: #EF5835;
$info: #8DC8C1;
$secondary: #8B8A8A;
$success: #63B82B;
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons";
.fc-daygrid-day.fc-day-sat, .fc-daygrid-day.fc-day-sun{
  background: repeating-linear-gradient( 45deg, #bcbcbd00, #cacaca 10px, #3c3c3c 10px, #525353 20px );
  opacity: 0.2;
}
.lenghtDatatable {
  padding: 7px 15px;
}
.fc-day .fc-timegrid-col-frame {
  border-bottom: 1px solid ;
}
.fc-daygrid-body {
border-bottom: 1px solid ;
}
.fc-day-today {
  background: rgb(244, 244, 244) !important;
  border: 1px solid $secondary;
}
.fc-daygrid-day-top, #fc-dom-1, .fc .fc-daygrid-day-top, .fc-today-button, .fc-daygrid-week-number{
  display: none !important;
}
#calendar{
  .progress{
    color: #FFF;
  }
  #fc-dom-2{
    margin: 0px 3px 15px;
    font-size: 20px;
  }
  .fc-prev-button{
    margin-left: -10px;
  }
  .fc-prev-button, .fc-next-button{
    margin-bottom: 15px;
    padding: 7px;
  }
  table{
    border-radius: 8px;
    border-color: $secondary!important;
    overflow: hidden;
    th,td{
      border-color: $secondary !important;
    }
    .fc-event-title{
      margin-top: -20px;
      ul{
        z-index: 9999 !important;
        text-align: right;
        padding-right: 12px;
        li{
          z-index: 9999 !important;
          font-size: 15px;
          margin-left: 3px;
        }
      }
    }
  }
  .fc-scroller{
    overflow: auto !important;
  }
  .fc-scroller-liquid-absolute{
    overflow: auto !important;
    min-height: 101%;
  }
  .list_icons_tickets{
    color: #FFF;
    text-align: right;
    right: 10px;
    padding-right: 12px;
    position: absolute;
    margin-top: -15px;
  }

  .fc-daygrid-event-harness{
    a{
      padding-left: 13px;
      padding-right: 13px;
    }
  }
  .fc-header-toolbar{
    .fc-toolbar-chunk:first-child{
      margin-top: -100px;
    }
    .fc-toolbar-chunk:last-child{
      position: absolute;
    }
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 3.5em;
  }
  thead{
    th{

      padding: 5px 6px;
    }
  }
  th{
    .fc-scrollgrid-sync-inner{
      text-transform: capitalize;
      text-align: left;
      font-weight: 500;
      padding-left: 5px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .day_big{
    text-align: left;
    font-size: 35px;
    font-weight: 400;
    padding: 5px 9px;
    padding-bottom: 0px;
    padding-top: 0;
    margin-top: -10px;
    // color: #6a6a6a;
    color: $secondary;
  }
  
  .week_bt-btn{
    margin: 8px 16px;
  }
  .hours_take{
    font-size: 13px;
    margin-top: -2px;
  }
  
}

#dataTable-Projects_wrapper{
  margin-top: 40px;
  #dataTable-Projects_length{
    display: none;
  }
}

#dataTable-Users_wrapper{
  margin-top: 40px;
  #dataTable-Users_length{
    display: none;
  }
}

.dt-buttons{
  display: none;
}
.list_export{
  float: left;
  border: 0.01em solid #222;
  border-radius: 6px;
  padding: 1px 1px;
  margin-bottom: 15px;
  .dropdown-menu{
    min-width: 174px;
  }
  .dropdown-toggle{
    li{
      float: right;
    }
  }
}

.fc-daygrid-event-dot {
  display: none;
  margin: 0;
}
body {
  background-color: #FFFFFF;
  .c-badge {
    white-space: break-spaces;
    text-align: left;
    padding: 8px 11px;
  }

  .loader_custom {
    float: right;
  }

  header,
  footer {
    z-index: 99;
  }
}

.img-flag{
  img{
    max-height: 25px;
  }
}
.showHideElements{
  background-color: rgba(202, 26, 26, 0.76);
  color: white;
  padding: 5px 20px 5px 45px;
  border-radius: 9px;
  font-weight: bold;
  margin: 0px 20px;
  input {
    font-size: 20px;
  }
  span{
    line-height: 29px;
  }
}